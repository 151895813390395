import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input, {
  editData,
  Image,
  mixFormateFormData,
  MultiSelect,
  Select,
  submitData,
  Textarea,
} from "../../../extra/Inputs/Input";
import { closeDialog } from "../../../../redux/slice/dialogSlice";
import DialogBox from "../../../extra/DialogueBox/DialogBox";
import Button from "../../../extra/Buttons/Button";
import { setToast } from "../../../extra/toast";
import { warning } from "../../../extra/Swal/Alert";
import { baseURL } from "../../../util/config";
import {
  createWebCourse,
  updateWebCourse,
} from "../../../../redux/slice/webCourseSlice";

const WebCourseAdd = () => {
  const { dialogueData } = useSelector((state) => state.dialogue);
  const dispatch = useDispatch();

  const { webCourseCategory } = useSelector((state) => state.webCourseCategory);
  const { webTask } = useSelector((state) => state.webTask);
  const { webCourseOpportunities } = useSelector(
    (state) => state.webCourseOpportunities
  );

  const courseCategoryOptions = webCourseCategory.map((res) => {
    return { name: res.categoryName, value: res._id };
  });
  const courseOppOptions = webCourseOpportunities.map((res) => {
    return { name: res.title, value: res._id };
  });
  const courseRoadMapOptions = webTask.map((res) => {
    return {
      name: `<div><img class="hw-30 m-10-right" src="${
        baseURL + res.image
      }"/><span>${res.taskName}</span></div>`,
      value: res._id,
    };
  });

  const [learnId, setLearnId] = useState("");
  const [learnData, setLearnData] = useState([]);
  const [learn, setLearn] = useState({
    learnTitle: "",
    learnDescription: "",
  });

  const learnBtn = [
    { name: `H2`, value: `<h2></h2>` },
    { name: `H3`, value: `<h3></h3>` },
    { name: `H4`, value: `<h4></h4>` },
    { name: `P`, value: `<p></p>` },
    { name: `ul (•)`, value: `<ul type="disc">\n\t<li></li>\n</ul>` },
    { name: `ul (◦)`, value: `<ul type="circle">\n\t<li></li>\n</ul>` },
    { name: `ul (■)`, value: `<ul type="square">\n\t<li></li>\n</ul>` },
    { name: `ol (1)`, value: `<ol type="1">\n\t<li></li>\n</ol>` },
    { name: `ol (A)`, value: `<ol type="A">\n\t<li></li>\n</ol>` },
    { name: `ol (a)`, value: `<ol type="a">\n\t<li></li>\n</ol>` },
    { name: `ol (I)`, value: `<ol type="I">\n\t<li></li>\n</ol>` },
    { name: `ol (i)`, value: `<ol type="i">\n\t<li></li>\n</ol>` },
  ];

  const [roadMapData, setRoadMapData] = useState([
    {
      taskId: "",
      mapIndex: 1,
    },
  ]);

  const [showText, setShowText] = useState("");
  const [highlightText, setHighlightText] = useState([]);

  useEffect(() => {
    if (dialogueData) {
      editData(dialogueData, "webCourseForm");
      setLearnData(dialogueData.learnIn);
      setHighlightText(dialogueData.headingHighlight || []);
      setRoadMapData(
        dialogueData.roadMap.slice().sort((a, b) => a.mapIndex - b.mapIndex)
      );
    }
  }, [dialogueData]);

  const handleSubmit = async (e) => {
    const addWebCourse = submitData(e);

    if (addWebCourse) {
      const courseOpportunity = addWebCourse?.courseOpportunitiesId?.split(",");

      const addSomeCourse = {
        ...addWebCourse,
        learnIn: learnData,
        roadMap: roadMapData.slice().sort((a, b) => a.mapIndex - b.mapIndex),
        courseOpportunitiesId: courseOpportunity,
        headingHighlight: highlightText,
      };

      const convertFormDataCourse = mixFormateFormData(addSomeCourse);
      console.log("addSomeCourse", addSomeCourse);
      console.log("convertFormDataCourse", convertFormDataCourse);
      console.log("...convertFormDataCourse", ...convertFormDataCourse);
      try {
        let response;
        if (dialogueData) {
          const payload = {
            convertFormDataCourse,
            webCourseId: dialogueData._id,
          };
          response = await dispatch(updateWebCourse(payload)).unwrap();
        } else {
          response = await dispatch(
            createWebCourse(convertFormDataCourse)
          ).unwrap();
        }
        response.status && dispatch(closeDialog());
      } catch (err) {
        console.log("err", err);
      }
    }
  };

  return (
    <DialogBox
      id={`webCourseForm`}
      title={`Course Dialogue`}
      handleSubmit={handleSubmit}
      columns={`col-xxl-8 col-xl-9 col-11`}
    >
      <div className="row align-items-start formBody">
        <div className="col-12 m-20-bottom">
          <div className="text-darkGray fs-14 m-5-bottom fw-600">
            Home Page Details
          </div>
          <div className="border-dashed-second-1 p-20 border-radius-10">
            <div className="row align-items-center">
              <div className="col-lg-6 col-12">
                <Input
                  type={`text`}
                  id={`name`}
                  name={`name`}
                  label={`Name`}
                  errorMessage={`Enter Name`}
                />
                <Textarea
                  type={`text`}
                  id={`description`}
                  name={`description`}
                  label={`Description`}
                  errorMessage={`Enter Description`}
                  row={2}
                />
              </div>
              <div className="col-lg-6 col-12">
                <Image
                  id={`icon`}
                  name={`icon`}
                  label={`Icon`}
                  errorMessage={`Enter Icon`}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 m-20-bottom">
          <div className="text-darkGray fs-14 m-5-bottom fw-600">
            Heading Tag Details
          </div>
          <div className="border-dashed-second-1 p-20 border-radius-10">
            <div className="row align-items-center">
              <div className="col-lg-6 col-12">
                <Input
                  type={`text`}
                  id={`headingTag`}
                  name={`headingTag`}
                  label={`Heading Tag`}
                  errorMessage={`Enter Heading Tag`}
                />
              </div>
              <div className="col-lg-6 col-12">
                <Input
                  type={`text`}
                  id={`headingDes`}
                  name={`headingDes`}
                  label={`Heading Des`}
                  errorMessage={`Enter Heading Des`}
                />
              </div>
              <div className="col-12">
                <div className="col-11">
                  <Input
                    type={`text`}
                    name={`highlite`}
                    id={`highlite`}
                    label={`Type Hightlight`}
                    ignore={true}
                    value={showText}
                    onChange={(e) => setShowText(e.target.value)}
                  />
                </div>
                <div className="col-1 h-100">
                  <Button
                    className={`bg-second text-light border-solid-second-2 w-100 h-100`}
                    icon={`ri-arrow-down-circle-line`}
                    type={`button`}
                    onClick={() => {
                      setHighlightText([...highlightText, showText]);
                      setShowText("");
                    }}
                  />
                </div>
                <div className="col-12">
                  <div
                    className={`mainHighlightShowBox w-100 bg-light p-10 border-radius-5 d-flex flex-wrap ${
                      highlightText?.length <= 0 && "height-51"
                    }`}
                  >
                    {highlightText?.map((res, index) => (
                      <div className="bg-second text-light m-5 p-10-x p-5-y fs-14 border-radius-5 d-flex align-items-center">
                        <div className="m-5-right">{res}</div>
                        <i
                          class="ri-close-circle-line fs-16 cursor-pointer"
                          onClick={() =>
                            setHighlightText(
                              highlightText.filter((_, i) => index !== i)
                            )
                          }
                        ></i>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 m-20-bottom">
          <div className="text-darkGray fs-14 m-5-bottom fw-600">
            Meta Tag Details
          </div>
          <div className="border-dashed-second-1 p-20 border-radius-10">
            <div className="row align-items-center">
              <div className="col-12">
                <Input
                  type={`text`}
                  id={`metaTitle`}
                  name={`metaTitle`}
                  label={`Meta Title`}
                  errorMessage={`Enter Meta Title`}
                />
              </div>
              <div className="col-lg-6 col-12">
                <Textarea
                  type={`text`}
                  id={`metaDescription`}
                  name={`metaDescription`}
                  label={`Meta Description`}
                  errorMessage={`Enter Meta Description`}
                  row={3}
                />
              </div>
              <div className="col-lg-6 col-12">
                <Textarea
                  type={`text`}
                  id={`metaKeywords`}
                  name={`metaKeywords`}
                  label={`Meta Keywords`}
                  errorMessage={`Enter Meta Keywords`}
                  row={3}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 m-20-bottom">
          <div className="text-darkGray fs-14 m-5-bottom fw-600">
            Course Page Details
          </div>
          <div className="border-dashed-second-1 p-20 border-radius-10">
            <div className="row align-items-start">
              <div className="col-lg-6 col-12">
                <Input
                  type={`text`}
                  id={`headTitle`}
                  name={`headTitle`}
                  label={`Head Title (H2)`}
                  errorMessage={`Enter Head Title`}
                />
                <Textarea
                  type={`text`}
                  id={`tagDescription`}
                  name={`tagDescription`}
                  label={`Tag Description (H2)`}
                  errorMessage={`Enter Tag Description`}
                  row={2}
                />
                <Input
                  type={`text`}
                  id={`courseLink`}
                  name={`courseLink`}
                  label={`Head Course Link`}
                  errorMessage={`Enter Head Course Link`}
                />
              </div>
              <div className="col-lg-6 col-12">
                <Input
                  type={`file`}
                  id={`image`}
                  name={`image`}
                  label={`Image`}
                  imageHandleClass={`width-300 height-150`}
                  errorMessage={`Enter Image`}
                />
              </div>
              <div className="col-lg-6 col-12">
                <Input
                  type={`text`}
                  id={`title`}
                  name={`title`}
                  label={`Title`}
                  errorMessage={`Enter Title`}
                />
                <Textarea
                  type={`text`}
                  id={`titleHtml`}
                  name={`titleHtml`}
                  label={`Title Html`}
                  errorMessage={`Enter Title Html`}
                  row={4}
                />
              </div>
              <div className="col-lg-6 col-12 h-100">
                <Select
                  id={`courseCategoryId`}
                  name={`courseCategoryId`}
                  label={`Course Category`}
                  errorMessage={`Enter Course Category`}
                  option={courseCategoryOptions}
                />
                <Input
                  type={`text`}
                  id={`duration`}
                  name={`duration`}
                  label={`Duration`}
                  errorMessage={`Enter Duration`}
                />
                <Input
                  type={`text`}
                  id={`hours`}
                  name={`hours`}
                  label={`Hours`}
                  errorMessage={`Enter Hours`}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 m-20-bottom">
          <div className="text-darkGray fs-14 m-5-bottom fw-600">
            Course Learn In
          </div>
          <div className="border-dashed-second-1 p-20 border-radius-10">
            <div className="row align-items-end">
              <div className="col-8">
                <Input
                  type={`text`}
                  id={`learnTitle`}
                  name={`learnTitle`}
                  label={`Learn Title`}
                  value={learn.learnTitle}
                  onChange={(e) =>
                    setLearn({ ...learn, [e.target.name]: e.target.value })
                  }
                  ignore={true}
                />
              </div>
              <div className="col-12">
                <Textarea
                  type={`text`}
                  id={`learnDescription`}
                  name={`learnDescription`}
                  label={`Learn Description`}
                  value={learn.learnDescription}
                  onChange={(e) =>
                    setLearn({ ...learn, [e.target.name]: e.target.value })
                  }
                  ignore={true}
                  row={5}
                />
              </div>
              <div className="col-12">
                {learnBtn.map((res) => (
                  <Button
                    className="bg-second text-light border-solid-second-2 m-10-right"
                    text={res.name}
                    type="button"
                    onClick={() => {
                      setLearn({
                        ...learn,
                        learnDescription:
                          learn.learnDescription == ""
                            ? `${learn.learnDescription}${res.value}`
                            : `${learn.learnDescription}\n${res.value}`,
                      });
                    }}
                  />
                ))}
              </div>
              <div className="col-2 m-10-top">
                <Button
                  className={`bg-gray2 text-darkGray border-solid-darkGray-2`}
                  text={`Add`}
                  type={`button`}
                  onClick={() => {
                    if (learn.learnDescription) {
                      if (learnId >= 0 && learnId !== "") {
                        setLearnData(
                          learnData.map((editVal, editI) =>
                            editI == learnId ? learn : editVal
                          )
                        );
                      } else {
                        setLearnData([...learnData, learn]);
                      }
                      setLearn({ learnTitle: "", learnDescription: "" });
                      setLearnId("");
                    } else {
                      setToast("error", "Enter Learn Description");
                    }
                  }}
                />
              </div>
              <div className="col-12 m-20-top">
                <div className="text-darkGray fs-14 m-5-bottom fw-600">
                  What You'll Learn
                </div>
                <div
                  className="bg-light p-10-y p-20-x border-radius-10"
                  style={{ minHeight: "100px" }}
                >
                  <ul className="mainLearn fs-14">
                    {learnData.map((res, i) => (
                      <>
                        <li
                          className={`d-flex align-items-center ${
                            learnId === i ? "text-gray" : "text-darkGray"
                          }`}
                        >
                          <div className="fs-18 m-8-right">
                            <i
                              class="ri-edit-2-line"
                              onClick={() => {
                                setLearn({
                                  learnTitle: res.learnTitle,
                                  learnDescription: res.learnDescription,
                                });
                                setLearnId(i);
                              }}
                            ></i>
                            <i
                              class="ri-delete-bin-line"
                              onClick={() => {
                                const data = warning();
                                data
                                  .then((logouts) => {
                                    const yes = logouts.isConfirmed;
                                    console.log("yes", yes);
                                    if (yes) {
                                      setLearnData(
                                        learnData.filter(
                                          (_, editI) => editI !== i
                                        )
                                      );
                                    }
                                  })
                                  .catch((err) => console.log(err));
                              }}
                            ></i>
                          </div>
                          {res.learnTitle != "" ? (
                            <div>
                              <h2
                                className="m-10-bottom"
                                dangerouslySetInnerHTML={{
                                  __html: res.learnTitle,
                                }}
                              ></h2>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: res.learnDescription,
                                }}
                              ></div>
                            </div>
                          ) : (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: res.learnDescription,
                              }}
                            ></div>
                          )}
                        </li>
                        {learnData.length - 1 != i && <hr className="m-20-y" />}
                      </>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 m-20-bottom">
          <div className="text-darkGray fs-14 m-5-bottom fw-600">
            Course Opportinities
          </div>
          <div className="border-dashed-second-1 p-20 border-radius-10">
            <div className="row align-items-center">
              <div className="col-12">
                <MultiSelect
                  type={`text`}
                  id={`courseOpportunitiesId`}
                  name={`courseOpportunitiesId`}
                  label={`Course Opportunities`}
                  errorMessage={`Enter Course Opportunities`}
                  options={courseOppOptions}
                  keys={`newCourseOpportunitiesId`}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 m-20-bottom">
          <div className="text-darkGray fs-14 m-5-bottom fw-600">
            Course Roadmap
          </div>
          <div className="border-dashed-second-1 p-20 border-radius-10">
            <div className="row align-items-end">
              {roadMapData.map((mapRes, ind) => (
                <div key={ind} className="row m-3 align-items-end">
                  <div className="col-md-6">
                    <Select
                      id={`roadMap${mapRes.mapIndex}`}
                      name={`roadMap${mapRes.mapIndex}`}
                      label={`Course Road Map`}
                      ignore={true}
                      option={courseRoadMapOptions}
                      onChange={(e) => {
                        setRoadMapData((prevRoadMapData) =>
                          prevRoadMapData.map((item, index) =>
                            index === ind ? { ...item, taskId: e } : item
                          )
                        );
                      }}
                      defaultValue={mapRes.taskId}
                    />
                  </div>
                  <div className="col-md-3 col-6">
                    <Input
                      type="text"
                      id={`MapIndex${mapRes.mapIndex}`}
                      name={`MapIndex${mapRes.mapIndex}`}
                      label={`Map Index`}
                      value={mapRes.mapIndex}
                      ignore={true}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setRoadMapData((prevRoadMapData) =>
                          prevRoadMapData.map((item, index) =>
                            index === ind
                              ? { ...item, mapIndex: newValue }
                              : item
                          )
                        );
                      }}
                    />
                  </div>
                  <div className="col-md-3 col-6 m-15-bottom">
                    <Button
                      className="bg-danger text-light border-solid-danger-2"
                      icon="ri-delete-bin-line"
                      type="button"
                      onClick={() => {
                        setRoadMapData((prevRoadMapData) =>
                          prevRoadMapData.filter((_, index) => index !== ind)
                        );
                      }}
                    />
                    <Button
                      className="bg-success text-light border-solid-success-2 m-20-left"
                      icon="ri-link"
                      type="button"
                      onClick={() => {
                        setRoadMapData((prevRoadMapData, roadIndex) => [
                          ...prevRoadMapData,
                          {
                            taskId: "",
                            mapIndex: roadMapData.length + 1,
                          },
                        ]);
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </DialogBox>
  );
};

export default WebCourseAdd;
